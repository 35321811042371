<template>
    <v-card flat>
        <v-card-title>
            <span class="secondary--text font-weight-bold">File Compare</span>
        </v-card-title>
        <v-card-text>
            <v-row style="min-height: 60px" align="center">
                <v-col cols="2">File name</v-col>
                <v-col cols="10">
                    <span class="secondary--text font-weight-bold">{{ currentFileUnderRevisionData.fileName }}</span>
                </v-col>
            </v-row>
            <v-row style="min-height: 60px" align="center">
                <v-col cols="2">Current version</v-col>
                <v-col cols="10">
                    <v-breadcrumbs class="ma-0 pa-0" :items="breadcrumbsItemsFirstLevel">
                        <template v-slot:item="props">
                            <v-breadcrumbs-item>
                                <span class="secondary--text font-weight-bold">{{ props.item.text }}</span>
                                <v-tooltip bottom v-if="props.item.index === 2">
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" class="ml-1" color="info">mdi-information-outline</v-icon>
                                    </template>
                                    <span>This is the current version of your file.</span>
                                </v-tooltip>
                            </v-breadcrumbs-item>
                        </template>
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-double-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </v-col>
            </v-row>
            <v-row style="min-height: 60px" align="center">
                <v-col cols="2">Previous version</v-col>
                <v-col cols="10">
                    <v-breadcrumbs class="ma-0 pa-0" :items="breadcrumbsItemsSecondLevel">
                        <template v-slot:item="props">
                            <v-breadcrumbs-item>
                                <v-menu offset-y v-if="props.item.index === 2">
                                    <template v-slot:activator="{ on }">
                                        <div>
                                            <span
                                                :class="
                                                    selectedSnapshot === 'select snapshot'
                                                        ? 'warning--text font-weight-bold'
                                                        : 'secondary--text font-weight-bold'
                                                ">
                                                {{ props.item.text }}
                                            </span>
                                            <v-dialog v-model="restoreDialog" max-width="500" v-if="selectedSnapshot !== 'select snapshot'">
                                                <template v-slot:activator="{ on: dialog }">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on: tooltip }">
                                                            <v-btn
                                                                color="error"
                                                                icon
                                                                v-on="{ ...tooltip, ...dialog }"
                                                                :disabled="disableRestore"
                                                                :loading="restoreLoading">
                                                                <v-icon>mdi-restore-alert</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Restore</span>
                                                    </v-tooltip>
                                                </template>

                                                <v-card>
                                                    <v-card-title>
                                                        <div class="secondary--text">
                                                            <v-icon class="mr-1">mdi-restore-alert</v-icon>
                                                            File Restore
                                                        </div>
                                                    </v-card-title>
                                                    <v-divider class="mb-1"></v-divider>
                                                    <v-card-text>
                                                        Restoring a previous version of a file will replace the current version of the file in your current
                                                        state with the previous.
                                                    </v-card-text>
                                                    <v-card-actions>
                                                        <v-spacer></v-spacer>
                                                        <v-btn color="error" text @click="restoreFile()">restore this version</v-btn>
                                                        <v-btn color="secondary" text @click="restoreDialog = false">cancel</v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>
                                        </div>
                                        <v-btn v-on="on" color="secondary" small icon :loading="compareLoading">
                                            <v-icon small>keyboard_arrow_down</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list dense v-model="selectedSnapshot">
                                        <v-list-item v-for="item in snapshotNames" :key="item" @click="compareFiles(item)">
                                            <v-list-item-title>{{ item }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                                <span v-else class="secondary--text font-weight-bold">{{ props.item.text }}</span>
                            </v-breadcrumbs-item>
                        </template>
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-double-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </v-col>
            </v-row>
            <div v-if="diffs" v-html="prettyHtml" />
            <v-alert
                prominent
                type="info"
                text
                class="mt-5"
                dense
                v-else-if="selectedSnapshot !== 'select snapshot' && !compareError && !tooBigToCompare && !compareLoading">
                <v-row align="center">
                    <v-col class="grow">The file version you have selected is identical to what you currently have.</v-col>
                    <v-col class="shrink">
                        <v-btn
                            :to="{
                                name: 'snapshot-files',
                                params: {
                                    oid: $route.params.oid,
                                    sid: $route.params.sid,
                                    iid: $route.params.iid,
                                    snid: $route.params.snid,
                                    localPath: currentFileLocalPath,
                                    fileArea: fileAreaType
                                }
                            }"
                            text
                            exact
                            color="secondary">
                            go back to files
                        </v-btn>
                    </v-col>
                </v-row>
            </v-alert>
            <v-alert prominent type="warning" text class="mt-5" dense v-else-if="selectedSnapshot !== 'select snapshot' && compareError && !compareLoading">
                <v-row align="center">
                    <v-col class="grow">An error has occurred. Refresh the page or try again later.</v-col>
                    <v-col class="shrink">
                        <v-btn
                            :to="{
                                name: 'snapshot-files',
                                params: {
                                    oid: $route.params.oid,
                                    sid: $route.params.sid,
                                    iid: $route.params.iid,
                                    snid: $route.params.snid,
                                    localPath: currentFileLocalPath,
                                    fileArea: fileAreaType
                                }
                            }"
                            text
                            exact
                            color="secondary">
                            go back to files
                        </v-btn>
                    </v-col>
                </v-row>
            </v-alert>
            <v-alert prominent type="warning" text class="mt-5" dense v-else-if="selectedSnapshot !== 'select snapshot' && tooBigToCompare && !compareLoading">
                <v-row align="center">
                    <v-col class="grow">File comparison has failed due to the large size of the file.</v-col>
                    <v-col class="shrink">
                        <v-btn
                            :to="{
                                name: 'snapshot-files',
                                params: {
                                    oid: $route.params.oid,
                                    sid: $route.params.sid,
                                    iid: $route.params.iid,
                                    snid: $route.params.snid,
                                    localPath: currentFileLocalPath,
                                    fileArea: fileAreaType
                                }
                            }"
                            text
                            exact
                            color="secondary">
                            go back to files
                        </v-btn>
                    </v-col>
                </v-row>
            </v-alert>
        </v-card-text>
    </v-card>
</template>

<script>
import { Diff2Html } from 'diff2html'
import 'diff2html/dist/diff2html.min.css'
import { mapGetters, mapState } from 'vuex'

export default {
    data() {
        return {
            diffs: '',
            expanded: [],
            singleExpand: false,
            selectedSnapshot: 'select snapshot',
            e13: 2,
            source: 'mdi-file',
            compare: 'mdi-arrow-down-thick',
            target: 'mdi-file',
            disableRestore: false,
            restoreLoading: false,
            restoreDialog: false,
            tooBigToCompare: false,
            compareError: false,
            compareLoading: false
        }
    },
    methods: {
        restoreFile: function () {
            this.restoreLoading = true
            this.restoreDialog = false
            const oldFidToRestore = this.currentFileUnderRevisionData.previousVersions.filter(
                snapshot => snapshot.snapshot_long_id === this.selectedSnapshot
            )[0].fid
            this.$axios
                .post(`/files/${this.currentFileUnderRevisionData.fid}/restore_version/${oldFidToRestore}`)
                .then(response => {
                    this.$router.push({
                        name: 'snapshot-files',
                        params: {
                            oid: this.$route.params.oid,
                            sid: this.$route.params.sid,
                            iid: this.$route.params.iid,
                            snid: this.$route.params.snid,
                            localPath: this.currentFileLocalPath,
                            fileArea: this.fileAreaType
                        }
                    })
                    this.dRestore = true
                    this.$store.dispatch('snapshotStore/setCurrentFileUnderRevisionData', {
                        fileName: this.currentFileUnderRevisionData.fileName,
                        fid: oldFidToRestore
                    })
                    this.$store.dispatch('showSnackBar', { snackBarText: 'File successfully restored!', snackBarTimeout: 5000, snackBarIcon: 'check_circle' })
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', { snackBarText: 'Could not restore file!', snackBarTimeout: 10000, snackBarIcon: 'error' })
                })
                .finally(() => {
                    this.restoreLoading = false
                })
        },
        compareFiles(item) {
            this.compareLoading = true
            this.selectedSnapshot = item
            this.tooBigToCompare = false
            this.compareError = false
            const fid = this.currentFileUnderRevisionData.previousVersions.filter(snapshot => snapshot.snapshot_long_id === item)[0].fid
            this.$axios
                .post(`/files/${fid}/diff_to/${this.currentFileUnderRevisionData.fid}`)
                .then(response => {
                    this.diffs = response.data
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.data.code === 'file_too_big') {
                            this.tooBigToCompare = true
                        }
                    } else {
                        this.compareError = true
                    }
                })
                .finally(() => {
                    this.compareLoading = false
                })
        }
    },
    computed: {
        ...mapState('snapshotStore', ['currentFileUnderRevisionData', 'fileAreaType']),
        ...mapGetters('snapshotStore', ['currentFileLocalPath', 'developmentSnapshot', 'nonDevelopmentSnapshots']),
        snapshotNames: function () {
            return this.currentFileUnderRevisionData.previousVersions.map(snapshot => snapshot.snapshot_long_id)
        },
        prettyHtml() {
            return Diff2Html.getPrettyHtml(this.diffs, {
                inputFormat: 'diff',
                showFiles: true,
                matching: 'lines',
                outputFormat: 'side-by-side',
                renderNothingWhenEmpty: false
            })
        },

        breadcrumbsItems() {
            return [
                {
                    text: this.$store.getters['spaceStore/spaceLongNameById'](this.$route.params.sid),
                    index: 0
                },
                {
                    text: this.$store.getters['instanceStore/instanceLongNameById'](this.$route.params.iid),
                    index: 1
                }
            ]
        },
        breadcrumbsItemsFirstLevel() {
            const secondSet = [
                {
                    text: 'Current State',
                    index: 2
                }
            ]
            return this.breadcrumbsItems.concat(secondSet)
        },
        breadcrumbsItemsSecondLevel() {
            const secondSet = [
                {
                    text: this.selectedSnapshot,
                    index: 2
                }
            ]
            return this.breadcrumbsItems.concat(secondSet)
        }
    },
    mounted() {
        const filePath = window.atob(this.$route.params.fid).replace(/^nvf:\/\/[0-9]+/, '')
        this.$store.dispatch('snapshotStore/setCurrentFileUnderRevisionData', { fileName: filePath, fid: this.$route.params.fid }).finally(() => {
            this.compareFiles(this.currentFileUnderRevisionData.previousVersions[0].snapshot_long_id)
        })
    }
}
</script>
